export const routes = [
    {
        path: "/home",
        name: "Home",
        component: () =>
            import(/* webpackChunkName: "our-vision" */ "../views/Home.vue"),
        meta: {
            title: 'Private-Games : votre espace privé, coquin, et communautaire de jeux érotiques pour couple(s)',
            description: 'Votre espace privé, coquin, et communautaire de jeux érotiques pour couple(s)',
            metaTags: [
                {
                    name: 'description',
                    content: 'Votre espace privé, coquin, et communautaire de jeux érotiques pour couple(s)'
                },
                {
                    property: 'og:description',
                    content: 'Votre espace privé, coquin, et communautaire de jeux érotiques pour couple(s)'
                },
                {
                    property: 'og:title',
                    content: 'Private-Games : votre espace privé, coquin, et communautaire de jeux érotiques pour couple(s)'
                },
                {
                    property: 'og:url',
                    content: 'https://www.private-games.com'
                }
            ],
            avaibleNavs: ['#home', '#canvas', '#comic-show']
        },
    },
    {
        path: "/our-vision",
        name: "OurVision",
        component: () =>
            import(/* webpackChunkName: "our-vision" */ "../views/OurVision.vue"),
        meta: {
            title: 'Private-Games : notre vision, la sexpérimentation partagée, 1 + 1 = 3',
            description: 'Tout le monde a sa place dans Private-Games, le couple n’est pas une somme d’individualités',
            metaTags: [
                {
                    name: 'description',
                    content: 'Tout le monde a sa place dans Private-Games, le couple n’est pas une somme d’individualités'
                },
                {
                    property: 'og:description',
                    content: 'Tout le monde a sa place dans Private-Games, le couple n’est pas une somme d’individualités'
                },
                {
                    property: 'og:title',
                    content: 'Private-Games : notre vision, la sexpérimentation partagée, 1 + 1 = 3'
                },
                {
                    property: 'og:url',
                    content: 'https://www.private-games.com/our-vision'
                }
            ],
            avaibleNavs: ['#home', '#sum-more', '#offer-more']
        }
    },
    {
        path: "/our-difference",
        name: "OurDifference",
        component: () =>
            import(/* webpackChunkName: "our-difference" */ "../views/OurDifference.vue"),
        meta: {
            title: 'Private-Games : notre différence, sexpérimenter ans sexposer',
            description: 'Pour nous, le couple n’est pas une somme d’individualités, mais plutôt une entité, une expérience sociale de la vraie vie, qui mérite d’être nourrie chaque jour, et dont chaque parti peut faire en sorte de la faire grandir.',
            metaTags: [
                {
                    name: 'description',
                    content: 'Pour nous, le couple n’est pas une somme d’individualités, mais plutôt une entité, une expérience sociale de la vraie vie, qui mérite d’être nourrie chaque jour, et dont chaque parti peut faire en sorte de la faire grandir.',
                },
                {
                    property: 'og:description',
                    content: 'Pour nous, le couple n’est pas une somme d’individualités, mais plutôt une entité, une expérience sociale de la vraie vie, qui mérite d’être nourrie chaque jour, et dont chaque parti peut faire en sorte de la faire grandir.',
                },
                {
                    property: 'og:title',
                    content: 'Private-Games : notre différence, sexpérimenter ans sexposer'
                },
                {
                    property: 'og:url',
                    content: 'https://www.private-games.com/our-difference'
                }
            ],
            avaibleNavs: ['#home']
        }
    }, {
        path: "/faq",
        name: "FAQ",
        component: () =>
            import(/* webpackChunkName: "our-difference" */ "../views/FAQ.vue"),
        meta: {
            title: 'Private-Games : foire aux questions !',
            description: 'Créer son compte, les jeux coquins, ... ',
            metaTags: [
                {
                    name: 'description',
                    content: 'Créer son compte, les jeux coquins, ... ',
                },
                {
                    property: 'og:description',
                    content: 'Créer son compte, les jeux coquins, ... ',
                },
                {
                    property: 'og:title',
                    content: 'Créer son compte, les jeux coquins, ... ',
                },
                {
                    property: 'og:url',
                    content: 'https://www.private-games.com/faq'
                }
            ],
            avaibleNavs: ['#home']
        }
    }, {
        path: "/cgu",
        name: "CGU",
        component: () =>
            import(/* webpackChunkName: "CGU" */ "../views/CGU.vue"),
        meta: {
            title: 'Private-Games : Conditions générales d’utilisation',
            description: 'Conditions générales d’utilisation ',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conditions générales d’utilisation ',
                },
                {
                    property: 'og:description',
                    content: 'Conditions générales d’utilisation ',
                },
                {
                    property: 'og:title',
                    content: 'Conditions générales d’utilisation ',
                },
                {
                    property: 'og:url',
                    content: 'https://www.private-games.com/cgu'
                }
            ],
            avaibleNavs: ['#home']
        }
    },
    {
        path: "/cgv",
        name: "CGV",
        component: () =>
            import(/* webpackChunkName: "CGV" */ "../views/CGV.vue"),
        meta: {
            title: 'Private-Games : Conditions générales de vente',
            description: 'Conditions générales de vente ',
            metaTags: [
                {
                    name: 'description',
                    content: 'Conditions générales de vente',
                },
                {
                    property: 'og:description',
                    content: 'Conditions générales de vente ',
                },
                {
                    property: 'og:title',
                    content: 'Conditions générales de vente ',
                },
                {
                    property: 'og:url',
                    content: 'https://www.private-games.com/cgv'
                }
            ],
            avaibleNavs: ['#home']
        }
    },
    {
        path: "*", component: () =>
            import(/* webpackChunkName: "our-vision" */ "../views/Home.vue")
    }
];