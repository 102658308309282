<template>
  <v-fade-transition>
    <v-footer
        padless
        app
        v-show="!isShowingHome"
    >
      <v-row
          justify="center"
          align="center"
          no-gutters
      >
        <v-col cols="12" class="text-center">
          <v-btn
              text
              ripple
              :to="{name:'CGU'}"
          >
            CGU
          </v-btn>
          <v-btn
              text
              ripple
              :to="{name:'CGV'}"
          >
            CGV
          </v-btn>
          <SocialMedia class="my-2"/>
        </v-col>
        <span class="caption">{{ new Date().getFullYear() }} — <strong>Private-Games - CBJ Média</strong></span>

      </v-row>
    </v-footer>
  </v-fade-transition>
</template>

<script>
import NavigationMixin from "../mixins/NavigationMixin";
import SocialMedia from "./SocialMedia";

export default {
  name: "PGFooter",
  components: {SocialMedia},
  mixins: [NavigationMixin],
  data: () => ({
    internals: [{
      to: {name: 'CGU'},
      text: 'CGU'
    },
      {
        to: {name: 'CGV'},
        text: 'CGV'
      }],
  }),
}
</script>

<style scoped>

</style>