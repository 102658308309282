<template>
  <v-app>
    <v-navigation-drawer app dark v-model="showNavigation" src="~@/assets/background/background-pg.jpg" temporary
                         v-if="$vuetify.breakpoint.mobile">
      <Menu/>
    </v-navigation-drawer>
    <v-app-bar app color="white" elevate-on-scroll id="home">
      <v-row no-gutters align="center">
        <v-col cols="3" sm="6">
          <v-img
              alt="Private Games"
              class="shrink mt-1"
              contain
              min-width="200"
              src="~@/assets/logo_bandeau-black.png"
              width="200"
              @click="$router.push({name:'Home'})"
              style="cursor: pointer"
          />
        </v-col>
        <v-col cols="9" sm="4" class="text-right" v-if="!$vuetify.breakpoint.mobile">
          <v-menu
              open-on-hover
              top
              offset-y
              light
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  class="pa-0"
              >
                À propos
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list style="background-color: #f1efeb;">
              <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  :to="{name:item.to}"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-divider></v-divider>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text :to="{name:'FAQ'}" class="pa-0" v-if="!$vuetify.breakpoint.mobile">FAQ</v-btn>
          <!--          <v-btn text>Blog</v-btn>-->
          <SocialMedia v-show="$vuetify.breakpoint.smAndUp"/>
        </v-col>
        <v-col cols="12" sm="2" class="text-center"
               v-if="!$vuetify.breakpoint.mobile">
          <v-btn class="pg-color-strong-orange mt-6 mt-sm-0" dark
                 href="https://app.private-games.com"
                 target="_blank">
            Jouer maintenant !
          </v-btn>
        </v-col>
        <v-fade-transition>
          <v-col cols="12" sm="10" v-if="!$vuetify.breakpoint.mobile">
            <revealing-catch-phrase/>
          </v-col>
        </v-fade-transition>
        <v-fade-transition>
          <v-col cols="2" class="text-center caption" v-if="$vuetify.breakpoint.lgAndUp">
            <span>Inscription gratuite <pg-icon  s-m inline>i-18-plus</pg-icon></span>
          </v-col>
        </v-fade-transition>
      </v-row>
      <v-btn icon @click="showNavigation=!showNavigation" v-show="$vuetify.breakpoint.mobile">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="fill-height" id="scroll-target">
      <v-fab-transition>
        <v-btn fab elevation="2" fixed top right class="pg-color-strong-orange mt-sm-16" dark to="#home"
               @click="goUp"
               v-show="!isShowingHome && !hasOnlyOnePage"
               v-if="!$vuetify.breakpoint.mobile"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn fab elevation="2" bottom right fixed @click="goDown" class="pg-color-strong-orange" dark
               v-show="!hasOnlyOnePage" v-if="!$vuetify.breakpoint.mobile">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-fab-transition>
      <router-view/>
      <p-g-footer/>
    </v-main>
  </v-app>
</template>

<script>

import RevealingCatchPhrase from "./components/RevzalingCatchPhrase";
import NavigationMixin from "./mixins/NavigationMixin";
import PGFooter from "./components/PGFooter";
import Menu from "./components/Menu";
import SocialMedia from "./components/SocialMedia";
import PgIcon from "./components/PGIcon";


export default {
  name: "App",
  mixins: [NavigationMixin],
  components: {
    PgIcon,
    SocialMedia,
    Menu,
    PGFooter,
    RevealingCatchPhrase
  },
  data: () => {
    return {
      showNavigation: false,
      items: [
        {
          title: 'Notre vision',
          to: 'OurVision'
        },
        {
          title: 'Notre différence',
          to: 'OurDifference'
        },
      ],
    }
  },
  computed: {
    scrollThreshold() {
      return this.$vuetify.breakpoint.mobile ? 50 : window.innerHeight;
    }
  },
  methods: {
    setCurrentNavFromRoute() {
      if (this.$route && this.$route.meta && this.$route.meta.avaibleNavs) {
        this.setAvaibleNavs(this.$route.meta.avaibleNavs);
      }
    },
    goDown() {
      this.goToNextNav().then(() => {
        this.$vuetify.goTo(this.currentNav).finally(() => {
          window.setTimeout(() => {
            this.isScrolling = false;
          }, 250);
        })
      })

    },
    goUp() {
      this.goToPrevNav().then(() => {
        this.$vuetify.goTo(this.currentNav).finally(() => {
          window.setTimeout(() => {
            this.isScrolling = false;
          }, 250);
        })
      })
    }
  },
  created() {
    if (this.$router.currentRoute.hash) {
      this.currentNav = this.$router.currentRoute.hash
    }
    this.setCurrentNavFromRoute();
    window.onscroll = this.onScroll;
    window.onkeydown = this.onKeyBoard;
  },
  watch: {
    '$route': function () {
      this.setCurrentNavFromRoute();
    }
  }

};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

$color-white: #ffffff;
$color-black: #000000;
$color-very-dark-pink: #660033;
$color-grey-80: #cccccc;
$color-dark-moderate-red: #993333;
$color-maroon: #660000;
$color-deep-red: #660033;
$color-petite-orchid: #cc9999;
$color-strong-orange: #cc9933;
$color-persian-red: #cc3333;
$color-black-transparent: rgba(0, 0, 0, 0.2);
$color-white-transparent: rgba(255, 255, 255, 0.9);

$body-font-family: 'Varela Round';
$title-font: 'Varela Round';

* {
  word-break: break-word !important;
}

.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title { // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}


.pg-color-very-dark-pink {
  background-color: $color-very-dark-pink !important;
}

.pg-color-very-dark-pink--text {
  color: $color-very-dark-pink !important;
}

.pg-color-maroon {
  background-color: $color-maroon !important;
}

.pg-color-deep-red {
  background-color: $color-maroon !important;
}

.pg-color-dark-moderate-red {
  background-color: $color-dark-moderate-red !important;
}

.pg-color-petite-orchid {
  background-color: $color-petite-orchid !important;
}

.pg-color-strong-orange {
  background-color: $color-strong-orange !important;
}

.pg-color-persian-red {
  background-color: $color-persian-red !important;
}

.pg-color-grey-80 {
  background-color: $color-grey-80 !important;
}

.pg-color-black {
  background-color: $color-black !important;
}

.pg-color-white {
  background-color: $color-white !important;
}

.pg-hot-gradient {
  background: linear-gradient(135deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
}

.pg-hot-gradient--text {
  font-size: 24px;
  background: -webkit-linear-gradient(135deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  display: inline-block
}

.pg-hot-gradient-reversed {
  background: linear-gradient(-45deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
}

.pg-hot-gradient-reversed--text {
  font-size: 24px;
  background: -webkit-linear-gradient(-45deg, #54000E, #811F31, #A02838, #A7462F, #B8642A, #BF7527, #C48D27, #C4B427, #D8D225);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pg-color-white-transparent {
  background-color: $color-white-transparent !important;
}

.pg-text-field fieldset {
  background-color: $color-black-transparent !important;
}
</style>
