import Vue from "vue";
import Vuex from "vuex";
import {getField, updateField} from 'vuex-map-fields';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentNav: '#home',
        isScrolling: false,
        isScrollingUser: false,
        currentNavIndex: 0,
        currentScroll: 0,
        avaibleNavs: ['#home', '#canvas', '#vivrate-invitation']
    },
    getters: {
        getField,
        getNbPages(state) {
            return state.avaibleNavs.length;
        },
        getCurrentPageNumber(state) {
            return state.currentNavIndex + 1;
        }
    },
    mutations: {
        updateField,
    },
    actions: {
        setAvaibleNavs({state}, navs) {
            state.avaibleNavs = navs;
        },
        goToNextNav({state}) {
            return new Promise(resolve => {
                if (state.avaibleNavs.length - state.currentNavIndex > 1) {
                    state.currentNavIndex++;
                    state.currentNav = state.avaibleNavs[state.currentNavIndex];
                }
                console.log(state.currentNav);
                resolve(state.currentNav);
            })
        },
        goToPrevNav({state}) {
            return new Promise(resolve => {
                if (state.currentNavIndex) {
                    state.currentNavIndex--;
                }
                state.currentNav = state.avaibleNavs[state.currentNavIndex];
                resolve(state.currentNav);
            });
        },
        setCurrentNav({state}, currentNav) {
            let index = state.avaibleNavs.indexOf(currentNav);
            if (index >= 0) {
                state.currentNav = currentNav;
                state.currentNavIndex = index;
            }
        },
        setCurrentPage({state}, pageNumber) {
            return new Promise(resolve => {
                let index = pageNumber - 1;
                if (index < 0) {
                    index = 0;
                }
                state.currentNav = state.avaibleNavs[index];
                resolve(state.currentNav);
            })
        }
    },
    modules: {}
});
