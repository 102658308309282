<template>
  <v-img v-bind:src="getImage" :max-width="getSize" :width="getSize" contain class="pg-icon-margin" :style="getStyle">
    <div ref="iconName" style="display: none">
      <slot></slot>
    </div>
  </v-img>
</template>

<script>
const SIZE_MAP = {
  small: '16px',
  default: '24px',
  medium: '28px',
  large: '36px',
  xLarge: '40px',
  xxLarge: '55px'
}
export default {
  name: "PgIcon",
  data() {
    return {
      iconName: ""
    };
  },
  props: {
    SM: Boolean,
    MD: Boolean,
    LG: Boolean,
    XL: Boolean,
    XXL: Boolean,
    mono: Boolean,
    inline:Boolean,
    color: {
      type: String,
      default: ""
    }
  },
  computed: {
    getImage() {
      let icon ='';
      if (this.iconName == "") {
        return icon;
      } else {
        icon = this.iconName.replace(/\s/g, '');
      }
      let image;
      try {
        image = require("../assets/icons/" + icon + (this.mono ? "-mono" : (this.color ? "-" + this.color : "")) + ".svg");
      } catch (e) {
        try {
          image = require("../assets/icons/" + icon + ".png");
        }
        catch (e2) {
          console.log(icon +"non disponible.")
          image="";
        }

      }
      return image;
    },
    getSize() {
      if (this.SM) return SIZE_MAP.small;
      if (this.MD) return SIZE_MAP.medium;
      if (this.LG) return SIZE_MAP.large;
      if (this.XL) return SIZE_MAP.xLarge;
      if (this.XXL) return SIZE_MAP.xxLarge;
      return SIZE_MAP.default;
    },
    getStyle(){
      return this.inline?'display: table-cell; ':'';
    }
  },
  mounted() {
    if (this.$refs.iconName && this.$refs.iconName.textContent) {
      this.iconName = this.$refs.iconName.textContent;
    }
  }
};
</script>

<style scoped>
.pg-icon-margin {
  margin-right: 2px;
  margin-left: 2px;
}
</style>
