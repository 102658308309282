<template>
  <div class="pg-color-very-dark-pink--text">
    <div id="title">Votre espace privé</div>
    <div id="subtext">
      <span>, coquin, et communautaire de jeux érotiques pour couple(s)</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RevealingCatchPhrase"
}
</script>

<style scoped>
div {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Lobster";
}

#title {
  /* For increasing performance
                     ID/Class should've been used.
                     For a small demo
                     it's okaish for now */
  animation: showup 10s;
  animation-iteration-count: 1;
}

#subtext {
  width: 655px;
  animation: reveal 10s;
  animation-iteration-count: 1;
}

#subtext span {
  margin-left: 0px;
  animation: slidein 10s;
  animation-iteration-count: 1;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    margin-left: -900px;
  }
  25% {
    margin-left: -271px;
  }
  50% {
    margin-left: -168px;
  }
  75% {
    margin-left: -51px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 655px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>