<template>
  <span>
    <v-btn
        v-for="(link,index2) in links"
        :key="index2"
        icon
        target="_blank"
        rounded
        :href="link.href"
    >
      <v-icon>{{ link.icon }}</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: "SocialMedia",
  data() {
    return {
      links: [
        {
          href: 'https://www.facebook.com/PrivateGamesApp',
          icon: 'mdi-facebook',
        },
        {
          href: 'https://www.instagram.com/privategames_app/',
          icon: 'mdi-instagram'
        }
      ],
    }
  }
}
</script>

<style scoped>

</style>