<template>
  <div>
    <v-list class="text-uppercase">
      <v-list-item class="px-2" :to="{name:'Home'}">
        <v-list-item-content>
          <v-img src="~@/assets/logo_bandeau-white.png"/>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <v-list-item-group
          v-model="selectedItem"
          color="primary"
      >
        <v-list-item :to="{name:'Home'}">
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
            :value="false"
            no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              À propos
            </v-list-item-content>
          </template>
          <v-list-item link :to="{name:'OurVision'}">
            <v-list-item-content>Notre vision</v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{name:'OurDifference'}">
            <v-list-item-content>Notre différence</v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item :to="{name:'FAQ'}">
          <v-list-item-content>
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-btn width="100%" class="pg-color-strong-orange mt-6 mt-sm-0 " dark
           href="https://app.private-games.com"
           target="_blank">Jouer maintenant !
    </v-btn>
    <div class="caption text-center white--text">Inscription
      gratuite réservée aux
      personnes majeures
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      selectedItem: "",
      items: [
        {
          icon: 'i-home',
          title: 'Accueil',
          link: {name: 'Home'}
        },
        {
          icon: 'i-couple-dice',
          title: 'Jeu de couple',
          link: {name: 'GameType', params: {typeCode: 'defi'}}
        },
        {
          icon: 'i-cards',
          title: 'Jeu de rencontre',
          link: {name: 'GameType', params: {typeCode: 'scenarios'}}
        },
        {
          icon: 'i-shop',
          title: 'Boutique',
          link: "/shop"
        }]
    }
  }
}
</script>

<style scoped>

</style>