import {createHelpers} from 'vuex-map-fields';
import {mapActions, mapGetters} from "vuex";

const {mapFields} = createHelpers({
    getterType: 'getField',
    mutationType: 'updateField'
});

export default {
    computed: {
        ...mapFields({
            currentNav: "currentNav",
            currentScroll: "currentScroll",
            isScrolling: "isScrolling",
            isScrollingUser: "isScrollingUser"
        }),
        ...mapGetters({getNbPages: "getNbPages", currentPageNumber: 'getCurrentPageNumber'}),
        isShowingHome() {
            return this.currentNav === '#home';
        },
        hasOnlyOnePage() {
            return this.getNbPages <= 1;
        }
    },
    methods: {
        ...mapActions({
            goToNextNav: "goToNextNav",
            goToPrevNav: "goToPrevNav",
            setCurrentNav: "setCurrentNav",
            setCurrentPage: "setCurrentPage",
            setAvaibleNavs: "setAvaibleNavs"
        }),
        changeNav(isDown) {
            if (!this.isScrollingUser && !this.hasOnlyOnePage) {
                this.isScrollingUser = true;
                this.isScrolling = true;
                if (isDown) {
                    this.goToPrevNav().then(scrollToId => {
                        if (scrollToId != undefined) {
                            this.$vuetify.goTo(scrollToId).finally(() => {
                                    this.isScrolling = false;
                                    this.isScrollingUser = false;
                                }
                            );
                        }
                    })
                } else {
                    this.goToNextNav().then(scrollToId => {
                        if (scrollToId != undefined) {
                            this.$vuetify.goTo(scrollToId).finally(() => {
                                this.isScrolling = false;
                                this.isScrollingUser = false;
                            })
                        }
                    })
                }
            }
        },
        onMouseWheel(event) {
            this.changeNav((event.deltaY < 0))
        },
        onKeyBoard(event) {
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                event.preventDefault();
                this.changeNav(event.key === "ArrowUp");
            }
        },
        onScroll() {

            if (!this.isScrolling && !this.isScrollingUser && !this.hasOnlyOnePage) {
                this.isScrolling = true;
                let page = Math.ceil((window.pageYOffset) / (window.innerHeight * 2 / 3));
                page = page > this.getNbPages ? this.getNbPages : page;
                this.setCurrentPage(page);
                window.setTimeout(() => {
                    this.isScrolling = false;
                }, 250)
            }
        }
    },
    created() {
        if (this.$vuetify.breakpoint.mdAndUp) {
            this.showNavigation = true;
        }
    },
}